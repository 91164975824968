import React from 'react';
import { useTranslate } from '../../hooks/common';
import { TRANSLATIONS } from '../../constants';
import ContentContainer from '../common/ContentContainer';
import Icon from '../common/Icon';
import { IconType } from '../../enums';

const Footer = () => {
    const translate = useTranslate();

    const renderWebsite = () => (
        <div className='footer-links footer-global-website'>
            <a className='footer-link' target='_blank' href={translate(TRANSLATIONS.footer.innomoticsWebsiteLink)} rel='noreferrer'>
                {translate(TRANSLATIONS.footer.innomoticsWebsite)}
            </a>
        </div>
    );

    const renderSocial = () => (
        <div className='footer-social-links'>
            <a className='social-link' href='https://www.linkedin.com/company/innomotics' title='LinkedIn' target='_blank' rel='noopener noreferrer'>
                <Icon type={IconType.Linkedin} />
            </a>
        </div>
    );

    const renderInnomotics = () => (
        <div className='footer-links footer-innomotics'>
            <a className='footer-link no-link'>
                {translate(TRANSLATIONS.footer.innomotics).replace('{date}', new Date().getFullYear().toString())}
            </a>
        </div>
    );

    const renderContact = () => (
        <div className='footer-contact'>
            <a className='footer-contact-button' href={translate(TRANSLATIONS.footer.contactUsLink)} target='_blank' rel='noopener noreferrer'>
                {translate(TRANSLATIONS.footer.contactUs)}
                <Icon type={IconType.Email} />
            </a>
        </div>
    );

    const renderLegal = () => (
        <div className='footer-links footer-legal'>
            <a className='footer-link footer-global-website-link' target='_blank' href={translate(TRANSLATIONS.footer.innomoticsWebsiteLink)} rel='noreferrer'>
                {translate(TRANSLATIONS.footer.innomoticsWebsite)}
            </a>
            <a className='footer-link no-link footer-innomotics-link'>
                {translate(TRANSLATIONS.footer.innomotics).replace('{date}', new Date().getFullYear().toString())}
            </a>
            <a className='footer-link' target='_blank' href={translate(TRANSLATIONS.footer.corporateInformationLink)} rel='noreferrer'>
                {translate(TRANSLATIONS.footer.corporateInformation)}
            </a>
            <a className='footer-link' target='_blank' href={translate(TRANSLATIONS.footer.privacyNoticeLink)} rel='noreferrer'>
                {translate(TRANSLATIONS.footer.privacyNotice)}
            </a>
            <a className='footer-link' target='_blank' href={translate(TRANSLATIONS.footer.cookieNoticeLink)} rel='noreferrer'>
                {translate(TRANSLATIONS.footer.cookieNotice)}
            </a>
            <a className='footer-link' target='_blank' href={translate(TRANSLATIONS.footer.termsOfUseLink)} rel='noreferrer'>
                {translate(TRANSLATIONS.footer.termsOfUse)}
            </a>
            <a className='footer-link' target='_blank' href={translate(TRANSLATIONS.footer.digitalIdLink)} rel='noreferrer'>
                {translate(TRANSLATIONS.footer.digitalId)}
            </a>
        </div>
    );

    return (
        <footer>
            <ContentContainer>
                <div className='footer-container'>
                    {renderWebsite()}
                    {renderSocial()}
                    {renderInnomotics()}
                    {renderContact()}
                    {renderLegal()}
                </div>
            </ContentContainer>
        </footer>
    );
};

export default Footer;
