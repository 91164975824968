import React, { AnimationEvent, KeyboardEvent, ReactText, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import innomoticsFl from '../../../assets/images/motors/INNOMOTICS_FL.png';
import innomoticsGp from '../../../assets/images/motors/INNOMOTICS_GP.png';
import innomoticsSd from '../../../assets/images/motors/INNOMOTICS_SD.png';
import innomoticsXp from '../../../assets/images/motors/INNOMOTICS_XP.png';
import { SPC_PROD_BASE_URL, SPC_TEST_BASE_URL, TIA_SCENARIO, TRANSLATIONS } from '../../constants';
import { IconType, Language } from '../../enums';
import { useAppSelector, useOpenAnimations, useTranslate } from '../../hooks/common';
import { Motor, SpcData } from '../../models';
import { getSelectedOptionNames, getTrackData, needAnimation, trackEvent } from '../../services';
import { addHiddenId, addNewId, addPinned, removeNewId, removePinned, toggleMore } from '../../store';
import Button, { ButtonType } from '../common/Button';
import Icon from '../common/Icon';
import Tooltip from '../common/Tooltip';
import EfficiencySymbol from './EfficiencySymbol';

enum CardAction {
    Pin,
    Remove
}

export enum CardType {
    Horizontal,
    Vertical
}

interface CardProps {
    motor: Motor;
    type: CardType;
}

const Card = ({ motor, type }: CardProps) => {
    const dispatch = useDispatch();
    const dtkData = useAppSelector(state => state.selector.dtkData);
    const spcData = useAppSelector(state => state.selector.spcData);
    const hiddenIds = useAppSelector(state => state.selector.hiddenIds);
    const moreOpen = useAppSelector(state => state.selector.moreOpen);
    const motors = useAppSelector(state => state.selector.motors ?? []);
    const newIds = useAppSelector(state => state.selector.newIds);
    const optionData = useAppSelector(state => state.selector.optionData);
    const pinnedMotorIds = useAppSelector(state => state.selector.pinnedMotors.map(x => x.id));
    const commercialData = useAppSelector(state => state.selector.commercialData);
    const loadingDeliveryTimes = useAppSelector(state => state.selector.loadingDeliveryTimes);
    const translate = useTranslate();
    const [animationClass, handleAnimationEnd, contentRef, height] = useOpenAnimations<HTMLDivElement>(moreOpen);
    const [activeAction, setActiveAction] = useState(CardAction.Pin);
    const [disappearing, setDisappearing] = useState(false);
    const [mlfbLabelClicked, setMlfbLabelClicked] = useState(false);
    const cartFormUrl = process.env.REACT_APP_IMALL_CART_FORM_URL;
    const cartFormRef = useRef<HTMLFormElement>(null);
    const configurationLinkTarget = spcData?.caller ? '_self' : '_blank';
    const currentMotor = commercialData?.find(x => x.baseMlfb === motor.mlfb);
    const deliveryTime = currentMotor?.deliveryTime ?? 0;
    const listPrice = currentMotor?.listPrice ?? 0;
    const currency = currentMotor?.currency === 'EUR' ? '€' : '$';
    const newMotor = newIds.includes(motor.id);
    const mlfbDifferences = Array.from({ length: 16 }).map((_, i) => motors.some(y => y.mlfb[i] !== motors[0].mlfb[i]));
    const motorIds = motors.map(x => x.id);
    const pinnedMotor = pinnedMotorIds.includes(motor.id);
    const activeLanguage = Language[Number(window.localStorage.getItem('language'))].toLowerCase();
    const isCypress = !!('Cypress' in window);

    const getMlfbWithOptions = (text: string, isCalledByDocuService = false) => {
        const selectedOptions = getSelectedOptionNames(optionData);
        const plusSign = isCalledByDocuService ? '%2B' : '+';
        const modifiedPlusSign = isCalledByDocuService ? `-Z${plusSign}` : plusSign;

        return selectedOptions.length ? `${text}${modifiedPlusSign}${selectedOptions.join(plusSign)}` : text;
    };

    const getSelectedOptions = () => {
        const selectedOptions = getSelectedOptionNames(optionData);

        return selectedOptions.join('+');
    };

    const getCardTypeClass = () => {
        switch (type) {
            case CardType.Horizontal:
                return 'card-horizontal';
            case CardType.Vertical:
                return 'card-vertical';
        }
    };

    const getMallCartContent = (mlfb: string, options: string) => {
        if (options) {
            mlfb += '-Z';
        }

        return `
            <?xml version="1.0" encoding="iso-8859-1"?>
            <!DOCTYPE cart SYSTEM "cart_v2.dtd">
            <cart IF_VERSION="2.0" APPLICATION_NAME="Quickselector" CURRENCY="EUR">
              <item LINE_ITEMNUMBER="1" PRODUCT_ID="${mlfb}" QUANTITY="1">
                <edi_text EDITYPE="B">${options}</edi_text>
              </item>
            </cart>`;
    };

    const getThumbnailImage = () => {
        switch (motor.marketingName) {
            case 'Innomotics GP':
                return innomoticsGp;
            case 'Innomotics SD':
                return innomoticsSd;
            case 'Innomotics XP':
                return innomoticsXp;
            case 'Innomotics FL':
                return innomoticsFl;
            default:
                return '';
        }
    };

    const track = (name: string) => trackEvent(getTrackData(name, { mlfb: motor.mlfb }, optionData));

    const handlePinClick = () => {
        if (needAnimation(motorIds, hiddenIds, pinnedMotorIds, motor.id)) {
            setActiveAction(CardAction.Pin);
            setDisappearing(true);
        } else {
            pinnedMotor ? dispatch(removePinned(motor.id)) : dispatch(addPinned(motor));
        }
    };

    const handleTrashClick = () => {
        setActiveAction(CardAction.Remove);
        setDisappearing(true);
    };

    const handleCardAnimationEnd = () => {
        if (newMotor) {
            dispatch(removeNewId(motor.id));
        } else {
            setDisappearing(false);

            switch (activeAction) {
                case CardAction.Pin:
                    dispatch(addNewId(motor.id));
                    pinnedMotor ? dispatch(removePinned(motor.id)) : dispatch(addPinned(motor));
                    break;
                case CardAction.Remove:
                    motorIds.includes(motor.id) && dispatch(addHiddenId(motor.id));
                    pinnedMotor && dispatch(removePinned(motor.id));
                    break;
            }
        }
    };

    const handleMoreAnimationEnd = (event: AnimationEvent<HTMLDivElement>) => {
        event.stopPropagation();
        handleAnimationEnd();
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === ' ') {
            event.preventDefault();
            dispatch(toggleMore());
        }
    };

    const handleDataSheetAndDrawingSubmit = () => {
        const url = `https://mall.industry.siemens.com/spice/docuservice/docuservice?configid=14&docu.mlfb=${getMlfbWithOptions(motor.mlfb, true)}&docu.inittype=MLFB`
            + `&docu.generationtype=VIEW&docu.viewid=PDFDB2D&region=WW&language=${activeLanguage}&docu.language.locale1=${activeLanguage}_WW&docu.language.locale2=en_WW`;

        window.open(url, '_blank');

        track('DataSheetAndDrawing');
    };

    const handleConfigureOrModifySubmit = () => {
        const windowAsAny = window as any;
        const baseUrl = windowAsAny.isProduction ? SPC_PROD_BASE_URL : SPC_TEST_BASE_URL;
        const target = isCypress ? '_self' : configurationLinkTarget;

        if (spcData && spcData.scenario === TIA_SCENARIO) {
            callSpcWithFormData(baseUrl, spcData, target);
            track('Configure');
        } else {
            if (dtkData) {
                openWindowWithPost(dtkData.resultUrl, target, motor.dtkData);
                track('Modify');
            } else if (spcData?.configId) {
                window.open(`${baseUrl}?configid=${spcData.configId}&region=${spcData.region}&language=${spcData.language}&mlfb=${getMlfbWithOptions(motor.mlfb)}`, target, 'noreferrer');
                track('Configure');
            } else {

                window.open(`${baseUrl}?configid=47&language=${activeLanguage?.toUpperCase()}&mlfb=${getMlfbWithOptions(motor.mlfb)}`, target, 'noreferrer');
                track('Configure');
            }
        }
    };

    const handleLabelClick = () => {
        navigator.clipboard.writeText(motor.mlfb);
        setMlfbLabelClicked(true);
    };

    const callSpcWithFormData = (url: string, spcData: SpcData, target: string) => {
        const form = document.createElement('form');
        const keys = Object.keys(spcData);

        form.setAttribute('method', 'post');
        form.setAttribute('action', url);
        form.setAttribute('target', target);

        keys.forEach(key => {
            if (spcData[key as keyof SpcData]) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = spcData[key as keyof SpcData] ?? '';
                form.appendChild(input);
            }
        });

        const mlfbInput = document.createElement('input');
        mlfbInput.type = 'hidden';
        mlfbInput.name = 'mlfb';
        mlfbInput.value = getMlfbWithOptions(motor.mlfb);
        form.appendChild(mlfbInput);
        document.body.appendChild(form);

        form.submit();

        document.body.removeChild(form);
    };

    const openWindowWithPost = (url: string, name: string, params: string) => {
        const form = document.createElement('form');
        const input = document.createElement('input');

        form.setAttribute('method', 'post');
        form.setAttribute('action', url);
        form.setAttribute('target', name);

        input.type = 'hidden';
        input.name = 'DATA';
        input.value = params;
        form.appendChild(input);

        document.body.appendChild(form);

        form.submit();

        document.body.removeChild(form);
    };

    const getIconType = () => pinnedMotor ? IconType.Pinned : IconType.Pin;

    const renderNameAndActions = () => (
        <div className='name-and-actions'>
            <div className='marketing-name' onClick={() => window.open(motor.marketingSite, '_blank')}>{motor.marketingName}</div>
            <div>
                <Button type={ButtonType.Tertiary} onClick={handlePinClick} cypressAttribute='pin'>
                    <Icon type={getIconType()} />
                </Button>
                <Button type={ButtonType.Tertiary} onClick={handleTrashClick} cypressAttribute='delete'>
                    <Icon type={IconType.Trash} />
                </Button>
            </div>
        </div>
    );

    const getPropertyHeight = (isLineProperty: boolean) => isLineProperty && type === CardType.Vertical ? 'double-height' : '';

    const renderProperty = (label: ReactText, value: ReactText | JSX.Element, isLineProperty = false) => (
        <div className='property'>
            <div className='property-label'>
                {label}
            </div>
            <div className={`property-value ${getPropertyHeight(isLineProperty)}`}>
                {value}
            </div>
        </div>
    );

    const renderPropertyWithIcon = (label: ReactText, value: ReactText | JSX.Element, isLineProperty = false) => (
        <div className='property-container'>
            <Icon type={IconType.CheckSmall} />
            {renderProperty(label, value, isLineProperty)}
        </div>
    );

    const renderDeliveryTimeProperty = () => (
        <div className='property' data-cy='delivery-time'>
            <div className='property-label'>
                {translate(TRANSLATIONS.main.deliveryTime)}
            </div>
            <div className={`property-value ${getPropertyHeight(false)} ${loadingDeliveryTimes ? 'placeholder' : ''}`} data-cy='delivery-time-value'>
                {deliveryTime === 0 || currentMotor?.deliveryTimeOnRequest
                    ? translate(TRANSLATIONS.main.onRequest)
                    : `${deliveryTime} ${translate(TRANSLATIONS.main.days)}`}
            </div>
        </div>
    );

    const renderListPriceProperty = () => (
        <div className='property' data-cy='list-price'>
            <div className='property-label'>
                {translate(TRANSLATIONS.main.listPrice)}
            </div>
            <div className={`property-value ${getPropertyHeight(false)} ${loadingDeliveryTimes ? 'placeholder' : ''}`} data-cy='list-price-value'>
                {listPrice === 0 || currentMotor?.listPriceOnRequest
                    ? translate(TRANSLATIONS.main.onRequest)
                    : `${listPrice} ${currency}`}
            </div>
        </div>
    );

    const renderAdditionalProperties = () => (
        <>
            {renderPropertyWithIcon(translate(TRANSLATIONS.main.torque), motor.torque)}
            {renderPropertyWithIcon(translate(TRANSLATIONS.main.efficiency), motor.eta)}
            {renderPropertyWithIcon(translate(TRANSLATIONS.main.iain), motor.iaIn)}
        </>
    );

    const renderCartForm = () => {
        const target = isCypress ? '_self' : '_blank';

        return (
            <form ref={cartFormRef} target={target} method='POST' encType='multipart/form-data' action={cartFormUrl}>
                <textarea readOnly hidden id='xmlpayload' name='xmlpayload' value={getMallCartContent(motor.mlfb, getSelectedOptions())} />
            </form>
        );
    };

    const renderMoreDetails = () => {
        return type === CardType.Vertical &&
            <>
                <div ref={contentRef} className={`more-details ${animationClass}`} style={{ height }} onAnimationEnd={handleMoreAnimationEnd}>
                    {renderAdditionalProperties()}
                </div>
                <div className='details-toggle' tabIndex={0} onClick={() => dispatch(toggleMore())} onKeyDown={handleKeyDown}>
                    <>{moreOpen ? translate(TRANSLATIONS.main.less) : translate(TRANSLATIONS.main.more)}</>
                </div>
            </>;
    };

    return (
        <div className={`card ${getCardTypeClass()} ${newMotor ? 'appearing' : ''} ${disappearing ? 'disappearing' : ''}`} onAnimationEnd={handleCardAnimationEnd}>
            <div className='thumbnail'>
                {type === CardType.Horizontal && renderNameAndActions()}
                <img src={getThumbnailImage()} />
            </div>
            <div className='header'>
                {type === CardType.Vertical && renderNameAndActions()}
                <Tooltip
                    delayShow={0}
                    id={motor.mlfb}
                    text={mlfbLabelClicked ? translate(TRANSLATIONS.main.copied) : translate(TRANSLATIONS.main.copyMlfb)}
                    afterShow={() => setMlfbLabelClicked(false)}
                >
                    <div className='mlfb' onClick={handleLabelClick} data-cy='mlfb'>
                        {motor.mlfb.split('').map((x, i) =>
                            <span key={i} className={mlfbDifferences[i] ? 'mlfb-highlight' : ''}>{x}</span>
                        )}
                    </div>
                </Tooltip>
                {type === CardType.Horizontal &&
                    <>
                        {renderProperty(translate(TRANSLATIONS.main.efficiencyClass), <EfficiencySymbol efficiencyClass={motor.efficiencyClass} />)}
                        {renderDeliveryTimeProperty()}
                    </>
                }
            </div>
            <div className='details'>
                {renderPropertyWithIcon(translate(TRANSLATIONS.main.power), motor.power)}
                {type === CardType.Vertical && renderPropertyWithIcon(translate(TRANSLATIONS.main.efficiencyClass), <EfficiencySymbol efficiencyClass={motor.efficiencyClass} />)}
                {renderPropertyWithIcon(translate(TRANSLATIONS.main.line), motor.description, true)}
                {renderPropertyWithIcon(translate(TRANSLATIONS.main.sizeOfMotor), motor.frameSize)}
                {renderMoreDetails()}
            </div>
            {type === CardType.Horizontal
                ? (
                    <div className='details'>
                        {renderAdditionalProperties()}
                    </div>
                ) : (
                    <div className='footer'>
                        {renderDeliveryTimeProperty()}
                        <Icon type={IconType.Delivery} />
                        {renderListPriceProperty()}
                    </div>
                )
            }
            <div className='actions'>
                <Button type={ButtonType.Secondary} onClick={handleDataSheetAndDrawingSubmit} cypressAttribute='download'>
                    <>
                        <Icon type={IconType.Document} />
                        {translate(TRANSLATIONS.main.dataSheetAndDrawing)}
                    </>
                </Button>
                <Button type={ButtonType.Secondary} onClick={() => {
                    cartFormRef.current?.submit();
                    track('AddToCart');
                }} cypressAttribute='add-to-cart'>
                    <>
                        <Icon type={IconType.Shoppingcart} />
                        {translate(TRANSLATIONS.main.addToCart)}
                    </>
                </Button>
                <Button type={ButtonType.Primary} onClick={handleConfigureOrModifySubmit} cypressAttribute='configure'>
                    <>
                        <Icon type={IconType.Configuration} />
                        {translate(dtkData ? TRANSLATIONS.main.modify : TRANSLATIONS.main.configure)}
                    </>
                </Button>
                {renderCartForm()}
            </div>
        </div>
    );
};

export default Card;
