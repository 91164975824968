export const CALLER = 'CALLER';
export const CONFIGID = 'CONFIGID';
export const COUNTRY = 'COUNTRY';
export const HOOK_URL = 'HOOKURL';
export const HOOK_TARGET = 'HOOKTARGET';
export const KEEP_ALIVE_URL = 'KEEPALIVEURL';
export const LANGUAGE = 'LANGUAGE';
export const REGION = 'REGION';
export const RESULT_URL = 'RESULTURL';
export const SCENARIO = 'SCENARIO';
